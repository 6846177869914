import React from 'react'
import styled from 'styled-components'

import Header from './Header'
import Content from './Content'
import Footer from './Footer'

const TemplateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  height: 100%;
  margin: 0;
`

const CloseNav = () => {
  document.getElementById("sidenav").style.width = "0px";
  document.getElementById("overlay").style.display = "none";
}

export default props =>  
  <TemplateWrapper>
    <div id="overlay" onClick={CloseNav}></div>
    <Header/>
    <Content children={props.children}/>
    <Footer/>
  </TemplateWrapper>