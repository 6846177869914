import React from 'react'
import styled from 'styled-components'
import { Flex } from 'reflexbox'
import { NavLink } from 'react-router-dom'

import SectionTitle from './SectionTitle'

const MainServicesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 30px 0;
  background-color: #e6e6e6;

  p.overview-text {
    max-width: 800px;
    font-size: 18px;
    line-height: 1.8;
  }
`

const ServiceBox = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  border-radius: 10px;
  background: #fff;
  max-width: 48%;
  margin: 12px 0px;
  box-shadow: 0px 1px 5px rgba(0,0,0,.15);

  @media (max-width: 992px) {
    max-width: 100%;
  }

  h1.service-title {
    font-size: 22px;
    color: var(--red);
    margin: 24px 24px 12px 24px;
  }

  hr.service-hline {
    color: var(--red);
    width: 100%;
    border-bottom: none;
    opacity: .25;
  }
  
  p.service-description {
    font-size: 17px;
    margin: 12px 24px;
    line-height: 1.6;
  }

  button.service-learn-more {
    border-radius: 4px;
    border: none;
    width: 120px;
    height: 40px;
    margin: 12px 24px 24px 24px;
    color: #fff;
    cursor: pointer;
    background-color: var(--red);
    box-shadow: 0px 1px 2px rgba(0,0,0,.3);
    font-size: 15px;
    opacity: 1;    
    transition: .2s;
  }

  button.service-learn-more:hover {
    opacity: .92;    
    transition: .2s;
  }
`

export default () =>
  <MainServicesWrapper className="container">
    {/* <SectionTitle title="Principais serviços" /> */}

    <Flex wrap align="flex-start" justify="space-between" className="container">
      <ServiceBox>
        <h1 className="service-title">Bafômetro</h1>
        <hr className="service-hline"/>
        <p className="service-description">
          O bafômetro ou Lei Seca é aplicada quando o motorista é flagrado dirigindo embriagado ou com 
          alguma quantidade de sangue no álcool. A punição que é imposta é uma multa de valor alto, 
          pontuação elevada e também a perda da habilitação.
        </p>
        <NavLink to="/servicos/bafometro">
          <button className="service-learn-more">Saiba mais</button>
        </NavLink>
      </ServiceBox>
      <ServiceBox>
        <h1 className="service-title">CNH Cassada</h1>
        <hr className="service-hline"/>
        <p className="service-description">
        Existem 3 casos que podem ocasionar a CNH Cassada: 1º Dirigir com a CNH Suspensa; 2º Quando o 
        infrator é reincidente, quando o motorista já teve a CNH suspensa, recuperou o documento e nos 
        primeiros 12 meses atingiu novamente 20 pontos; 3º Caso seja condenado judicialmente por algum 
        delito no trânsito.
        </p>
        <NavLink to="/servicos/cnh-cassada">
          <button className="service-learn-more">Saiba mais</button>
        </NavLink>
      </ServiceBox>
      <ServiceBox>
        <h1 className="service-title">CNH Suspensa</h1>
        <hr className="service-hline"/>
        <p className="service-description">
          A CNH suspensa ocorre quando o motorista atinge 20 pontos na carteira dentro do período de 1 
          ano ou também por certas penalizações como dirigir alcoolizado, conduzir motocicleta sem capacete, 
          Transitar em velocidade acima de 50% à máxima permitida ou Forçar passagem entre veículos que 
          trafegam em sentidos opostos para realizar ultrapassagem (que são infrações gravíssimas).
        </p>
        <NavLink to="/servicos/cnh-suspensa">
          <button className="service-learn-more">Saiba mais</button>
        </NavLink>
      </ServiceBox>
      <ServiceBox>
        <h1 className="service-title">Infrações</h1>
        <hr className="service-hline"/>
        <p className="service-description">
          Constitui infração de trânsito a inobservância de qualquer preceito do CTB, da legislação complementar 
          ou das Resoluções do CONTRAN, sendo o infrator sujeito às penalidades e medidas administrativas indicadas 
          em cada artigo. A infração deve ser comprovada por declaração da autoridade ou do agente da autoridade de 
          trânsito, por aparelho eletrônico ou por equipamento audiovisual, reações químicas ou por qualquer outro 
          meio tecnologicamente disponível, previamente regulamentado pelo CONTRAN.
        </p>
        <NavLink to="/servicos/infracoes">
          <button className="service-learn-more">Saiba mais</button>
        </NavLink>
      </ServiceBox>
    </Flex>
  </MainServicesWrapper>
