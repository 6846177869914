import React, { Component } from 'react'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

const ContactFormWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px;

  h2.form-title {    
    /* color: var(--red); */
    /* color: var(--purple); */
    /* color: var(--orange); */
    font-size: 18px;
    text-align: center;
    margin-bottom: 15px;
  }

  .text-field {
    margin-top: 8px;
    background: rgba(0,0,0,.015);
  }

  .text-field > input:focus+div>hr {
    border-bottom: 2px solid rgb(0, 0, 0) !important;
  }

  .btn-send {
    margin-top: 15px;
  }  
`

export default class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      form: {
        Nome: '',
        Email: '',
        Telefone: '',
        Mensagem: '',
      }      
    }
  }

  updateField(e) {
    const form = { ...this.state.form }
    form[e.target.name] = e.target.value
    this.setState({ form })
  }

  render() {
    return (
      <ContactFormWrapper>

        <h2 className="form-title">Comente seu problema que entraremos em contato:</h2>

        <form
          action="//formspree.io/vargas@vargasassessoria.com"
          method="POST"
        >        

          <TextField
            required={true}
            className="text-field"
            type="text"
            fullWidth={true}
            variant='outlined'
            margin="dense"
            name="Nome"
            label="Nome"
            value={this.state.Nome}
            onChange={e => this.updateField(e)}
          />
        
          <TextField
            required={true}
            htmlFor="_replyto"
            className="text-field"
            fullWidth={true}
            variant='outlined'
            margin="dense"
            name="Email"
            label="E-mail"
            type="email"
            value={this.state.Email}
            onChange={e => this.updateField(e)}
          />
        
          <TextField
            className="text-field"
            fullWidth={true}
            variant='outlined'
            margin="dense"
            name="Telefone"
            label="Telefone"
            type="phone"
            value={this.state.Telefone}
            onChange={e => this.updateField(e)}
          />    
        
          <TextField
            required={true}
            className="text-field"
            type="text"
            fullWidth={true}
            variant='outlined'
            margin="dense"
            multiline={true}
            rows={3}
            rowsMax={3}
            name="Mensagem"
            label="Mensagem"
            placeholder="Explique brevemente sobre a sua pendência ou sobre como podemos lhe ajudar"
            value={this.state.Mensagem}
            onChange={e => this.updateField(e)}
          />    

          <input type="hidden" name="_subject" value="Mensagem recebida de VargasAssessoria.com" />
          <input type="hidden" name="_format" value="plain" />
          <input type="hidden" name="_language" value="pt-BR" />
          <input type="hidden" name="_next" value="https://vargasassessoria.com/contato-sucesso" />

          <Button
            type="submit"
            children={"Enviar"}
            fullWidth={true}          
            variant="contained"
            className="btn-send"
            color="primary"
            style={{
              background: "var(--red)",
              /* background: "linear-gradient(to bottom, #ff8500, #fd7e02, #fb7605, #f86f08, #f6670b)", */
              textTransform: "none",
              padding: "12px 0",
              color: "#fff",         
              boxShadow: '0px 1px 4px rgba(0,0,0,.3)',       
            }}
          />  

        </form>   

      </ContactFormWrapper>
    )
  }
}