import React, { Component, Fragment } from 'react'
import { Helmet } from "react-helmet"
import styled from 'styled-components'
import { Flex, Box } from 'reflexbox'

import pageBanner from '../../assets/img/11.jpg'
import PageHeader from '../template/PageHeader'

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 60px 0px;

  h1.page-title {
    font-size: 38px;
    margin-bottom: 25px;
    color: var(--red);
  }

  p.page-text {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`


class QuemSomos extends Component {
  render() {
    return (
      <Fragment>

        <Helmet>
          <title>Quem Somos - Vargas Assessoria de Trânsito - Porto Alegre-RS</title>
        </Helmet>

        <PageHeader pageBanner={pageBanner} pageTitle={"Quem Somos"}/>

        <div className="container">
          <ContentWrapper>
            <h1 className="page-title">
              Sobre a empresa
            </h1>
            <p className="page-text">
              A Vargas Assessoria de Trânsito nasceu no ano de 2000, na cidade de Porto Alegre 
              e desde então vem atendendo clientes através de seu escritório, hoje situado na 
              Rua Carlos Von Koseritz, nº 25, procurando dar suporte técnico, administrativo e 
              jurídico a todos os nossos mais de 4000 clientes ativos, prezando sempre pelo bom 
              atendimento, lealdade e competência em todos os setores da empresa. Portanto, 
              estamos à disposição para sempre melhor atendê-los.              
            </p>
            <p className="page-text">
              Lauvir Rodrigues de Vargas<br/>
              Diretor Presidente
            </p>
          </ContentWrapper>
        </div>
        

      </Fragment>  
    )
  }
}
export default QuemSomos
  