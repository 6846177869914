import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Flex, Box } from "reflexbox";
import Iframe from "react-iframe";

import pageBanner from "../../assets/img/carousel2-.jpg";
import PageHeader from "../template/PageHeader";

import ContactForm from "../home/ContactForm";

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 60px 0px;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  a:hover {
    text-decoration: underline;
  }

  h1.page-title {
    font-size: 38px;
    margin-bottom: 25px;
    color: var(--red);
  }

  h2.page-subtitles {
    font-size: 16px;
    font-weight: bold;
    margin: 5px 0px;
  }

  p.page-text {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .contact-wrapper {
    margin-right: 50px;

    @media (max-width: 768px) {
      margin-right: 0px;
      margin-bottom: 40px;
    }
  }
`;

const ContactBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 100%;
  border-radius: 20px;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.05);
  background: #fff;
`;

const BoxIframe = styled.div`
  width: 100%;
  height: 390px;
  margin-bottom: 70px;
  display: flex;
`;

class Contato extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>
            Contato - Vargas Assessoria de Trânsito - Porto Alegre-RS
          </title>
        </Helmet>

        <PageHeader pageBanner={pageBanner} pageTitle={"Contato"} />

        <div className="container">
          <ContentWrapper>
            <Flex column className="contact-wrapper">
              <h1 className="page-title">Contato</h1>
              <p className="page-text">
                Atendemos de segunda a sexta-feira, das 9h às 18h.
              </p>
              <p className="page-text">
                <h2 className="page-subtitles">Telefones</h2>
                (51) 3342-8720
                <br />
                <a
                  href="https://api.whatsapp.com/send?phone=5551999114944"
                  target="__blank"
                >
                  (51) 99911-4944
                </a>
              </p>
              <p className="page-text">
                <h2 className="page-subtitles">E-mail</h2>
                <a href="mailto:vargas@vargasassessoria.com">
                  vargas@vargasassessoria.com
                </a>
              </p>
              <p className="page-text">
                <h2 className="page-subtitles">Endereço</h2>
                Rua Carlos Von Koseritz, 25
                <br />
                Bairro São João - Porto Alegre-RS
                <br />
              </p>
            </Flex>
            <Flex>
              <ContactBox id="contact-box">
                <ContactForm />
              </ContactBox>
            </Flex>
          </ContentWrapper>
          <BoxIframe>
            <Iframe
              url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3455.0029987057187!2d-51.19395018488582!3d-30.008070281895627!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x951979e824d95e2f%3A0x4019f01bcf58dc64!2sVargas+Assessoria+de+Transito!5e0!3m2!1spt-BR!2sbr!4v1547396599551"
              width="100%"
              height="390px"
              allowfullscreen
              position="relative"
              styles={{
                border: "1px solid #e6e6e6",
                boxShadow: "0px 0px 15px 3px rgba(0,0,0,.05)",
                borderRadius: "20px",
              }}
            ></Iframe>
          </BoxIframe>
        </div>
      </Fragment>
    );
  }
}
export default Contato;
