import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import windowSize from 'react-window-size'

import LogoMin from './LogoMin'
import LogoMax from './LogoMax'

class Logo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      scrollTop: 0,
      windowWidth: this.props.windowWidth
    }
  }

  componentDidMount() {   
    window.onscroll = () => {
      this.setState({ 
        scrollTop: document.documentElement.scrollTop
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.windowWidth !== prevProps.windowWidth) {
      this.setState({
        windowWidth: this.props.windowWidth
      })
    }
  }

  render() {  
    return (      
      <Link to="/">
        {this.state.scrollTop < 50 && this.state.windowWidth > 975 ? <LogoMax/> : <LogoMin/>}
      </Link>
    )  
  }
}

export default windowSize(Logo)