import React from 'react'
import styled from 'styled-components'

import SectionTitle from './SectionTitle'

const OverviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 70px 0px;
  width: 100%;

  p.overview-text {
    max-width: 800px;
    font-size: 18px;
    line-height: 1.8;
  }
`

export default () =>
  <OverviewWrapper className="container">
    <SectionTitle title="Assessoria jurídica e administrativa" />
    <p className="overview-text">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec malesuada, dui id luctus scelerisque, 
      lacus tellus eleifend felis, et bibendum risus est sit amet elit. Mauris vulputate ullamcorper nisl, 
      sit amet luctus ante laoreet tincidunt. Etiam varius erat sem.
    </p>
  </OverviewWrapper>
