import "./Footer.css";
import "../../libs/fontello/css/fontello.css";

import React from "react";
import { Flex, Box } from "reflexbox";
import { NavLink } from "react-router-dom";

import logo from "../../assets/img/logoMin_.png";

import facebookCircle from "../../assets/icons/social-media/svg/circle-facebook.svg";
import linkedinCircle from "../../assets/icons/social-media/svg/circle-linkedin.svg";
import instagramCircle from "../../assets/icons/social-media/svg/circle-instagram.svg";
import youtubeCircle from "../../assets/icons/social-media/svg/circle-youtube.svg";

export default () => (
  <footer className="footer">
    <Flex wrap align="flex-start" justify="space-between" className="container">
      <Flex
        column
        id="footer-item-1"
        className="footer-item"
        style={{ width: "400px" }}
      >
        <img
          className="footer-logo"
          src={logo}
          alt="Vargas"
          width="173px"
          height="23px"
        />
        {/* <h1>
          Vargas Assessoria de Trânsito
        </h1> */}
        {/* <span>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. In scelerisque vehicula nulla. 
          Pellentesque iaculis, nunc id consectetur vestibulum, est lorem ultrices massa.
        </span> */}
        <span className="footer-copyright">
          &copy; 2018 Vargas Assessoria de Trânsito.
        </span>
      </Flex>
      <Flex column className="footer-item">
        <h1>Serviços</h1>
        <ul>
          {/* <li><a className="footer-nav" href="/">Recurso</a></li>
          <li><a className="footer-nav" href="/">Defesa prévia</a></li>
          <li><a className="footer-nav" href="/">Bafômetro</a></li>
          <li><a className="footer-nav" href="/">Suspensão da CNH</a></li>
          <li><a className="footer-nav" href="/">Balada Segura</a></li>
          <li><a className="footer-nav" href="/">Suspensão de Pontuação</a></li>
          <li><a className="footer-nav" href="/">CNH Permissão</a></li> */}
          <li>
            <NavLink className="footer-nav" to="/servicos/bafometro">
              Bafômetro
            </NavLink>
          </li>
          <li>
            <NavLink className="footer-nav" to="/servicos/cnh-cassada">
              CNH Cassada
            </NavLink>
          </li>
          <li>
            <NavLink className="footer-nav" to="/servicos/cnh-suspensa">
              CNH Suspensa
            </NavLink>
          </li>
          <li>
            <NavLink className="footer-nav" to="/servicos/infracoes">
              Infrações
            </NavLink>
          </li>
        </ul>
      </Flex>
      <Flex column className="footer-item">
        <h1>Informações úteis</h1>
        <ul>
          <li>
            <a
              className="footer-nav"
              href="http://www.planalto.gov.br/ccivil_03/LEIS/L9503.htm"
              target="_blank"
              rel="noopener noreferrer"
            >
              Código de Trânsito
            </a>
          </li>
          <li>
            <NavLink
              className="footer-nav"
              to="/informacoes-uteis/calendario-licenciamento"
            >
              Calendário de Licenciamento
            </NavLink>
          </li>
          <li>
            <a
              className="footer-nav"
              href="https://www.detran.rs.gov.br/habilitacao-cnh/servicos/949"
              target="_blank"
              rel="noopener noreferrer"
            >
              Curso de reciclagem
            </a>
          </li>
        </ul>
      </Flex>
      <Flex column className="footer-item">
        <h1>Contato</h1>
        <span>Rua Carlos Von Koseritz, 25</span>
        <span>Bairro São João - CEP 90540-031</span>
        <span>Porto Alegre - RS - Brasil</span>
        <Flex column className="mail-phone" mt={"10px"}>
          <span>
            <i className="icon-phone"></i>(51) 3342-8720
          </span>
          <span>
            <i className="icon-whatsapp"></i>
            <a
              href="https://api.whatsapp.com/send?phone=5551999114944"
              target="__blank"
            >
              (51) 99911-4944
            </a>
          </span>
          <span>
            <i className="icon-mail"></i>
            <a href="mailto:vargas@vargasassessoria.com">
              vargas@vargasassessoria.com
            </a>
          </span>
        </Flex>
      </Flex>
    </Flex>
  </footer>
);
