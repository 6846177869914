import React from 'react'
import styled from 'styled-components'

const SectionTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1.section-title {
    font-size: 32px;
    font-weight: 500;
    max-width: 550px;
    margin-bottom: 15px;
  }

  hr.section-hr {
    width: 100px;
    height: 0;
    border-top: none;
    border-bottom: 1px solid #999;
    margin: 0px 0 15px 0;
  }
`

export default props =>
  <SectionTitle>
    <h1 className="section-title">
      {props.title}
    </h1>
    <hr className="section-hr"/>
  </SectionTitle>
