import React, { Fragment } from 'react'
import { Helmet } from "react-helmet"
import styled from 'styled-components'

import Banner from './Banner'
import Banner3 from './Banner3'
import Overview from './Overview'
import MainServices from './MainServices'

const ServicosWrapper = styled.div`
  background: #fff;
  padding: 60px 0px;
`

const ServicosContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`

const ServicosBox = styled.div`
  width: 450px;
  height: 350px;
  background: #fff;
  border-radius: 15px;
  margin: 20px 0px;
  box-shadow: 0px 0px 15px 5px rgba(0,0,0,.05);
`

export default () =>
  <Fragment>
    <Helmet>
      <title>Vargas Assessoria de Trânsito - Porto Alegre-RS</title>
    </Helmet>

    <Banner/>

    {/* <Overview/> */}

    <MainServices/>    

    {/* <Banner3/> */}
  </Fragment>
