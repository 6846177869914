import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router'
import { BrowserRouter } from 'react-router-dom'

import ScrollToTop from './components/template/ScrollToTop'

import Template from './components/template/Template'

import Home from './components/home/Home'
import QuemSomos from './components/quem-somos/QuemSomos'
import Bafometro from './components/servicos/bafometro/Bafometro'
import CnhCassada from './components/servicos/cnh-cassada/CnhCassada'
import CnhSuspensa from './components/servicos/cnh-suspensa/CnhSuspensa'
import Infracoes from './components/servicos/infracoes/Infracoes'
import Contato from './components/contato/Contato'
import ContatoSucesso from './components/contato/ContatoSucesso'
import CalendarioLicenciamento from './components/informacoes-uteis/CalendarioLicenciamento'

export default class App extends Component {
  render() {
    return (      
      <BrowserRouter onUpdate={() => window.scrollTo(0, 0)}>
        <ScrollToTop>
          <Template>
            <Switch>                     
              <Route exact path="/" component={Home} />           
              <Route exact path="/quem-somos" component={QuemSomos} />
              <Route exact path="/servicos/bafometro" component={Bafometro} />
              <Route exact path="/servicos/cnh-cassada" component={CnhCassada} />
              <Route exact path="/servicos/cnh-suspensa" component={CnhSuspensa} />
              <Route exact path="/servicos/infracoes" component={Infracoes} />
              <Route exact path="/informacoes-uteis/calendario-licenciamento" component={CalendarioLicenciamento} />
              <Route exact path="/contato" component={Contato} />
              <Route exact path="/contato-sucesso" component={ContatoSucesso} />
              <Redirect from='*' to='/'/>    
            </Switch> 
          </Template>
        </ScrollToTop>          
      </BrowserRouter>        
    )
  }
}