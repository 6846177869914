import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import mailIcon from '../../assets/icons/mail/mail.png'

const ContatoSucessoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 80px;
  padding: 130px 35px;

  img {
    opacity: .85;
  }

  h1.contact-success {
    font-size: 26px;
    margin-bottom: 10px;
    font-weight: 700;
  }

  h2.contact-success {
    font-weight: 500;
  }

  h2.contact-success-goback {
    margin-top: 30px;
    font-weight: 500;
  }

  a {	
    color: #1a1a1a;	
    text-decoration: none;
  }
  
  a::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #1a1a1a;
    transition: width .3s;	
    margin-top: 3px;
  }
  
  a:hover::after {	
    width: 100%;
    transition: width .3s;
  }
`

export default () =>
  <ContatoSucessoWrapper className="container">

    <img src={mailIcon} alt="Ícone de e-mail" width="120px" height="120px" />

    <h1 className="contact-success">
      Sua mensagem foi enviada com sucesso.
    </h1>

    <h1 className="contact-success">
      Retornaremos assim que tivermos uma posição.
    </h1>

    <Link to="/">
      <h2 className="contact-success-goback">
        Voltar à página inicial
      </h2>
    </Link>
    
  </ContatoSucessoWrapper>
