import React, { Component, Fragment } from 'react'
import { Helmet } from "react-helmet"
import styled from 'styled-components'
import { Flex, Box } from 'reflexbox'

import pageBanner from '../../../assets/img/pexels-photo-288476.jpeg'
import PageHeader from '../../template/PageHeader'

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 60px 0px;

  h1.page-title {
    font-size: 38px;
    margin-bottom: 25px;
    color: var(--red);
  }

  p.page-text {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`


class Bafometro extends Component {
  render() {
    return (
      <Fragment>

        <Helmet>
          <title>Bafômetro - Vargas Assessoria de Trânsito - Porto Alegre-RS</title>
        </Helmet>

        <PageHeader pageBanner={pageBanner} pageTitle={"Bafômetro"}/>

        <div className="container">
          <ContentWrapper>
            <h1 className="page-title">
              Bafômetro
            </h1>
            <p className="page-text">
              O bafômetro ou Lei Seca é aplicada quando o motorista é flagrado dirigindo embriagado 
              ou com alguma quantidade de sangue no álcool. A punição que é imposta é uma multa de 
              valor alto, pontuação elevada e também a perda da habilitação.
            </p>
            <p className="page-text">
              Segundo o art. 165 do Código Brasileiro de Trânsito, estas são as punições do ato: 
            </p>
            <p className="page-text">
              Art. 165. Dirigir sob a influência de álcool ou de qualquer outra substância psicoativa que determine dependência:<br/>
              Infração – gravíssima;<br/>
              Penalidade – multa (dez vezes) e suspensão do direito de dirigir por 12 (doze) meses.<br/>
              Medida administrativa – recolhimento do documento de habilitação e retenção do veículo, observado o disposto no § 4º do art. 270 da Lei nº 9.503 de 23 de setembro de 1997 do Código de Trânsito Brasileiro.<br/>
              Parágrafo único. Aplica-se em dobro a multa prevista no caput em caso de reincidência no período de até 12 (doze) meses.<br/>
            </p>
          </ContentWrapper>
        </div>
        

      </Fragment>  
    )
  }
}
export default Bafometro
  