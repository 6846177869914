import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

const NavWrapper = styled.div`  
  overflow: hidden;
  margin-top: 22px;  
  font-weight: 500;
  text-transform: uppercase;

  .item {
    display: inline-block;
    color: #1a1a1a;
    margin: 0px 0 0 45px;
    font-size: 14px;
    transition: .3s;
  }

  .item::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #1a1a1a;
    transition: width .3s;	
  }

  .item:hover::after {	
    width: 100%;
    transition: width .3s;
  }

  .item-servicos {
    cursor: pointer;
    display: inline-block;
    height: 50px;
    color: #1a1a1a;
    padding: 0px 0px;
    margin: 0px 0 0 45px;
    font-size: 14px;
    transition: .3s;
  }

  .dropdown-content-1 {
    display: none;
    position: absolute;
    background-color: var(--yellow);
    z-index: 1;
    margin: 10px -20px;
    border-radius: 10px;
    

    .item {
      display: block;
      color: #1a1a1a;
      margin: 15px 20px;
      font-size: 14px;
      transition: .3s;
    }
  }

  .item-servicos:hover .dropdown-content-1 {
    display: block;
  }

  .dropdown-content-1:hover {
    display: block;
  }
`

export default () =>
  <NavWrapper id="topnav" className="topnav">

    <NavLink to="/" className="item">
      Início
    </NavLink>

    <NavLink to="/quem-somos" className="item">
      Quem Somos
    </NavLink>

    <div className="item-servicos">
      Serviços

      <div className="dropdown-content-1">
        <NavLink to="/servicos/bafometro" className="item">
          Bafômetro
        </NavLink>

        <NavLink to="/servicos/cnh-cassada" className="item">
          CNH Cassada
        </NavLink>

        <NavLink to="/servicos/cnh-suspensa" className="item">
          CNH Suspensa
        </NavLink>

        <NavLink to="/servicos/infracoes" className="item">
          Infrações
        </NavLink>
      </div>
        
    </div>

    <div className="item-servicos">
      Informações úteis

      <div className="dropdown-content-1">
        <a href="http://www.planalto.gov.br/ccivil_03/LEIS/L9503.htm" className="item" target="_blank" rel="noopener noreferrer">
          Código de Trânsito
        </a>

        <NavLink to="/informacoes-uteis/calendario-licenciamento" className="item">
          Calendário de Licenciamento
        </NavLink>

        <a href="https://www.detran.rs.gov.br/habilitacao-cnh/servicos/949" className="item" target="_blank" rel="noopener noreferrer">
          Curso de Reciclagem
        </a>
      </div>
        
    </div>

    <NavLink to="/contato" className="item">
      Contato
    </NavLink>

  </NavWrapper>