import React, { Component, Fragment } from 'react'
import { Helmet } from "react-helmet"
import styled from 'styled-components'
import { Flex, Box } from 'reflexbox'

import pageBanner from '../../../assets/img/architecture-blur-bridge-1769293.jpg'
import PageHeader from '../../template/PageHeader'

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 60px 0px;

  h1.page-title {
    font-size: 38px;
    margin-bottom: 25px;
    color: var(--red);
  }

  h2.page-subtitle {
    font-size: 24px;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  p.page-text {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  ul.infracoes-penalidades {
    font-size: 18px;
    margin: 0px;
    line-height: 1.8;

    li {
      margin: 0px;
    }
  }
  
`


class Infracoes extends Component {
  render() {
    return (
      <Fragment>

        <Helmet>
          <title>Infrações - Vargas Assessoria de Trânsito - Porto Alegre-RS</title>
        </Helmet>

        <PageHeader pageBanner={pageBanner} pageTitle={"Infrações"}/>

        <div className="container">
          <ContentWrapper>
            <h1 className="page-title">
              Infrações
            </h1>
            <p className="page-text">
              Constitui infração de trânsito a inobservância de qualquer preceito do CTB, da legislação 
              complementar ou das Resoluções do CONTRAN, sendo o infrator sujeito às penalidades e medidas 
              administrativas indicadas em cada artigo. A infração deve ser comprovada por declaração da 
              autoridade ou do agente da autoridade de trânsito, por aparelho eletrônico ou por equipamento 
              audiovisual, reações químicas ou por qualquer outro meio tecnologicamente disponível, 
              previamente regulamentado pelo CONTRAN.              
            </p>
            <h2 className="page-subtitle">
              Penalidades
            </h2>
            <p className="page-text">
              As penalidades consistem em punições ou sanções administrativas aplicadas ao infrator da 
              legislação de trânsito indicada em cada um dos tipos infracionais descritos no capítulo XV 
              e no artigo 95 do CTB. A competência para aplicar a penalidade é da autoridade de trânsito.
            </p>
            <p className="page-text">
              As penalidades podem ser:
            </p>
            <ul className="infracoes-penalidades">
              <li>Advertência por escrito</li>
              <li>Multa</li>
              <li>Suspensão do direito de dirigir</li>
              <li>Apreensão do veículo</li>
              <li>Cassação da CNH</li>
              <li>Cassação da Permissão Para Dirigir</li>
              <li>Freqüência obrigatória em curso de reciclagem</li>
            </ul>
          </ContentWrapper>
        </div>
        

      </Fragment>  
    )
  }
}
export default Infracoes
  