import React from 'react'
import { MdMenu, MdClose } from 'react-icons/md'
import { Flex } from 'reflexbox'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

const NavMinWrapper = styled.div`
  .sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 2;
    top: 0;
    right: 0;
    background-color: #0d0d0d;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 50px;
    box-shadow: 0px 0px 10px rgba(0,0,0,.3);
  }

  .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 18px;
    color: #d9d9d9;
    display: block;
    transition: 0.5s;
  }

  .sidenav a:hover {
    color: #fff;
  }

  .sidenav .closebtn {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .sidenav h1.nav-section-title {
    color: #fff;
    font-size: 18px;
    padding: 24px 8px 18px 32px;
  }
`

const OpenNav = () => {
  document.getElementById("sidenav").style.width = "250px";
  document.getElementById("overlay").style.display = "block";
}

const CloseNav = () => {
  document.getElementById("sidenav").style.width = "0px";
  document.getElementById("overlay").style.display = "none";
}

const NavMin = () => {
  return (
    <Flex h={1} align="center" style={{ cursor: "pointer" }}>   
      <MdMenu size={32} onClick={OpenNav}/>

      <NavMinWrapper>
        <div id="sidenav" className="sidenav">
          <a className="closebtn" onClick={CloseNav}><MdClose size={28}/></a>
          <NavLink to="/" onClick={CloseNav}>
            Início
          </NavLink>

          <NavLink to="/quem-somos" onClick={CloseNav}>
            Quem Somos
          </NavLink>

          <NavLink to="/contato" onClick={CloseNav}>
            Contato
          </NavLink>

          <h1 className="nav-section-title" onClick={CloseNav}>
            Serviços
          </h1>
          
          <NavLink to="/servicos/bafometro" onClick={CloseNav}>
            Bafômetro
          </NavLink>

          <NavLink to="/servicos/cnh-cassada" onClick={CloseNav}>
            CNH Cassada
          </NavLink>

          <NavLink to="/servicos/cnh-suspensa" onClick={CloseNav}>
            CNH Suspensa
          </NavLink>

          <NavLink to="/servicos/infracoes" onClick={CloseNav}>
            Infrações
          </NavLink>

          <h1 className="nav-section-title" onClick={CloseNav}>
            Informações úteis
          </h1>

          <a href="http://www.planalto.gov.br/ccivil_03/LEIS/L9503.htm" target="_blank" rel="noopener noreferrer" onClick={CloseNav}>
            Código de Trânsito
          </a>

          <NavLink to="/informacoes-uteis/calendario-licenciamento" onClick={CloseNav}>
            Calendário de Licenciamento
          </NavLink>

          <a href="https://www.detran.rs.gov.br/habilitacao-cnh/servicos/949" target="_blank" rel="noopener noreferrer" onClick={CloseNav}>
            Curso de Reciclagem
          </a>          
        </div>
      </NavMinWrapper>       
    </Flex>
  )
}

export default NavMin