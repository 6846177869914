import React from 'react'
import styled from 'styled-components'
import { Flex } from 'reflexbox'

import bannerBg from '../../assets/img/7.jpg'
import ContactForm from './ContactForm'

const BannerWrapper = styled.div`
  width: 100%;
  height: 75vh;
  margin-top: 100px;
  background: url(${bannerBg}) no-repeat;
  background-position: bottom center;
  background-size: cover;
  display: flex;
  align-items: center;

  h1.slogan {    
    max-width: 800px;
    color: #fff;
    font-size: 46px;
    font-weight: 700;
    text-shadow: 2px 2px 5px rgba(0,0,0,.25);

    @media (max-width: 767px) {
      font-size: 38px;
    }
  }

  p.description {
    max-width: 650px;
    font-weight: 400;
    color: #fff;
    margin-top: 20px;
    padding: 0;
    font-size: 20px;
    text-shadow: 2px 2px 5px rgba(0,0,0,.1);

    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 1.7;
    }
  }
`

const ContactBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 100%;
  border-radius: 20px;
  box-shadow: 0px 0px 5px 5px rgba(0,0,0,.1);
  background: #fff;
`

export default () =>
  <BannerWrapper id="banner-wrapper">
    <Flex className="container" align="center" justify="space-between">
      
      <Flex id="banner-text-intro" column mr={"100px"} mt={4}>
        <h1 className="slogan">
          Resolva pendências de trânsito
        </h1>
        {/* <h1 className="slogan">
          Resolva suas pendências como motorista
        </h1> */}
        <p className="description">
          Com mais de 18 anos de tradição no mercado, oferecemos o suporte
          que você precisa para resolver pendências com multas de trânsito.
        </p>
      </Flex>
      <Flex>
        <ContactBox id="contact-box">
          <ContactForm/>
        </ContactBox>
      </Flex>
      
    </Flex>
  </BannerWrapper>
