import React, { Component, Fragment } from 'react'
import { Helmet } from "react-helmet"
import styled from 'styled-components'
import { Flex, Box } from 'reflexbox'

import pageBanner from '../../../assets/img/11.jpg'
import PageHeader from '../../template/PageHeader'

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 60px 0px;

  h1.page-title {
    font-size: 38px;
    margin-bottom: 25px;
    color: var(--red);
  }

  h2.page-subtitle {
    font-size: 24px;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  p.page-text {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`


class CnhSuspensa extends Component {
  render() {
    return (
      <Fragment>

        <Helmet>
          <title>CNH Suspensa - Vargas Assessoria de Trânsito - Porto Alegre-RS</title>
        </Helmet>

        <PageHeader pageBanner={pageBanner} pageTitle={"CNH Suspensa"}/>

        <div className="container">
          <ContentWrapper>
            <h1 className="page-title">
              CNH Suspensa
            </h1>
            <p className="page-text">
              A CNH suspensa ocorre quando o motorista atinge 20 pontos na carteira dentro 
              do período de 1 ano ou também por certas penalizações como dirigir alcoolizado, 
              conduzir motocicleta sem capacete, Transitar em velocidade acima de 50% à máxima 
              permitida ou Forçar passagem entre veículos que trafegam em sentidos opostos para 
              realizar ultrapassagem (que são infrações gravíssimas).              
            </p>
            <h2 className="page-subtitle">
              Minha CNH está suspensa, o que devo fazer? 
            </h2>
            <p className="page-text">
              Você receberá uma carta em seu endereço avisando que foi aberto um processo administrativo 
              da Suspensão de CNH, mas fique atento <u><strong>ISSO NÃO SIGNIFICA QUE VOCÊ DEVE ENTREGAR SUA CARTEIRA</strong></u>. 
              A partir desse momento você possui de 15 a 30 dias para apresentar sua defesa para reverter 
              esse problema. Neste momento nós teremos 3 etapas de defesa para ajudar você, o 1 momento é 
              a Defesa Prévia junto ao Detran, logo em seguida a Jari e por último o CETRAN. Durante todos
              esses 3 períodos você pode continuar dirigindo normalmente.
            </p>
            <p className="page-text">
              A <strong>Vargas Assessoria de Trânsito</strong> está desde 2000 ajudando vários motoristas com 
              este problema de CNH Suspensa, com uma gama de profissionais qualificados prontos para ajudar você 
              com esse problema. Desta forma podemos ajuda-lo a continuar dirigindo no período em que seu processo 
              administrativo está ativo. 
            </p>
          </ContentWrapper>
        </div>
        

      </Fragment>  
    )
  }
}
export default CnhSuspensa
  