import React from "react";
import styled from "styled-components";
import { Flex, Box } from "reflexbox";

import "../../libs/fontello/css/fontello.css";

import Logo from "./Logo";
import Nav from "./Nav";

const HeaderWrapper = styled.div`
  width: 100%;
  height: 100px;
  background: #fff;
  position: fixed;
  top: 0;
  background: var(--yellow);
  /* background: #ff8500; */
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
  /* border-bottom: 1px solid #e6e6e6; */
  z-index: 2;
`;

const ContactRow = styled.div`
  /* display: none; */
  background: #1a1a1a;
  color: #fff;

  span {
    font-size: 0.8rem;
    display: flex;
    align-items: center;
  }

  a:hover {
    text-decoration: underline;
  }

  i {
    margin-left: 10px;
    margin-right: 2px;
  }
`;

export default () => (
  <HeaderWrapper id="header">
    <ContactRow id="header-contact">
      <Flex className="header-contact-box container" justify="flex-end" py={1}>
        <Flex>
          <span>
            <i className="icon-mail"></i>{" "}
            <a href="mailto:vargas@vargasassessoria.com">
              vargas@vargasassessoria.com
            </a>{" "}
          </span>
          <span>
            <i className="icon-phone"></i> (51) 3342-8720{" "}
          </span>
          <span>
            <i className="icon-whatsapp"></i>{" "}
            <a
              href="https://api.whatsapp.com/send?phone=5551999114944"
              target="__blank"
            >
              (51) 99911-4944
            </a>{" "}
          </span>
        </Flex>
      </Flex>
    </ContactRow>

    <Flex className="container" justify="space-between">
      <Logo />
      <Nav />
    </Flex>
  </HeaderWrapper>
);
