import React from 'react'
import styled from 'styled-components'

import logo from '../../assets/img/logoMin_.png'

const LogoWrapper = styled.div`
  background: var(--yellow);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 10px 0px;
  margin-top: 10px;
  transition: opacity 1s ease-in-out;
`

const LogoImg = styled.img.attrs({
  src: logo,
  alt: 'Vargas Assessoria de Trânsito',
  height: '100%',
  width: '100%',
})``

const LogoMin = () => {
  return (
    <LogoWrapper id="logo">
      <LogoImg className="logo-img" />
    </LogoWrapper>
  )
}

export default LogoMin