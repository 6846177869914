import React, { Component, Fragment } from 'react'
import { Helmet } from "react-helmet"
import styled from 'styled-components'

import pageBanner from '../../assets/img/11.jpg'
import PageHeader from '../template/PageHeader'

import './CalendarioLicenciamento.css'

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 60px 0px;

  h1.page-title {
    font-size: 38px;
    margin-bottom: 25px;
    color: var(--red);
  }

  p.page-text {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`

class CalendarioLicenciamento extends Component {
  render() {
    return (
      <Fragment>

        <Helmet>
          <title>Calendário de Licenciamento - Vargas Assessoria de Trânsito - Porto Alegre-RS</title>
        </Helmet>

        <PageHeader pageBanner={pageBanner} pageTitle={"Calendário de Licenciamento"} />

        <div className="container">
          <ContentWrapper>
            <h1 className="page-title">
              Calendário de Licenciamento 2019
            </h1>
            <table class="tabelatiny1" border="1" cellspacing="0" cellpadding="0">
              <tbody>
                <tr>
                  <td>
                    <p align="center">Final das Placas</p>
                  </td>
                  <td>
                    <p align="center">Data limite de Pagamento do IPVA 2019 e Seguro Obrigatório</p>
                  </td>
                  <td>
                    <p align="center">Data limite de Validade do Licenciamento de 2018</p>
                  </td>
                </tr>
                <tr>
                  <td width="73" valign="top">
                    <p align="center">1</p>
                    <p align="center">2</p>
                    <p align="center">3</p>
                  </td>
                  <td width="235" valign="top">
                    <p align="center">01/04/2019</p>
                    <p align="center">03/04/2019</p>
                    <p align="center">05/04/2019</p>
                  </td>
                  <td width="154" valign="top">
                    <p align="center">&nbsp;</p>
                    <p align="center">30/04/2019</p>
                  </td>
                </tr>
                <tr>
                  <td width="73" valign="top">
                    <p align="center">4</p>
                    <p align="center">5</p>
                    <p align="center">6</p>
                  </td>
                  <td width="235" valign="top">
                    <p align="center">08/04/2019</p>
                    <p align="center">10/04/2019</p>
                    <p align="center">12/04/2019</p>
                  </td>
                  <td width="154" valign="top">
                    <p align="center">&nbsp;</p>
                    <p align="center">31/05/2019</p>
                  </td>
                </tr>
                <tr>
                  <td width="73" valign="top">
                    <p align="center">7</p>
                    <p align="center">8</p>
                  </td>
                  <td width="235" valign="top">
                    <p align="center">15/04/2019</p>
                    <p align="center">17/04/2019</p>
                  </td>
                  <td width="154">
                    <p align="center">30/06/2019</p>
                  </td>
                </tr>
                <tr>
                  <td width="73" valign="top">
                    <p align="center">9</p>
                    <p align="center">0</p>
                  </td>
                  <td width="235" valign="top">
                    <p align="center">22/04/2019</p>
                    <p align="center">24/04/2019</p>
                  </td>
                  <td width="154">
                    <p align="center">31/07/2019</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </ContentWrapper>
        </div>


      </Fragment>
    )
  }
}
export default CalendarioLicenciamento
