import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from 'reflexbox'

const PageHeaderWrapper = styled.div`
  width: 100%;
  height: 45vh;
  background: ${props => `url(${props.pageBanner}) no-repeat`};
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: center;

  h1.page-title {
    color: #fff;
  }
`

export default (props) =>  
  <PageHeaderWrapper pageBanner={props.pageBanner}>
    {/* <div className="container">
      <Flex mt={4} w={1} h={1} align="center" justify="flex-end">
        <h1 className="page-title">{props.pageTitle}</h1>
      </Flex>
    </div> */}
  </PageHeaderWrapper>