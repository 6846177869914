import React, { Fragment } from 'react'
import windowSize from 'react-window-size'

import NavFull from './NavFull'
import NavMin from './NavMin'

const Nav = (props) => {  
  return (
    <Fragment>
      {props.windowWidth > 975 ? <NavFull/> : <NavMin/>}
    </Fragment>
  )
}  

export default windowSize(Nav)