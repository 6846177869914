import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from 'reflexbox'

import bannerBg from '../../assets/img/11.jpg'
import ContactForm from './ContactForm'

const BannerWrapper = styled.div`
  width: 100%;
  height: 70vh;
  background: url(${bannerBg}) no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: center;

  h1.slogan {    
    color: #fff;
    font-size: 42px;
    font-weight: 700;
    text-shadow: 2px 2px 5px rgba(0,0,0,.25);
  }

  p.description {
    font-weight: 400;
    color: #fff;
    margin-top: 20px;
    padding: 0;
    font-size: 20px;
    text-shadow: 2px 2px 5px rgba(0,0,0,.1);
  }
`

const ContactBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 100%;
  border-radius: 20px;
  box-shadow: 0px 0px 5px 5px rgba(0,0,0,.1);
  background: #fff;
`

export default () =>
  <BannerWrapper >
    <Flex className="container" align="center" justify="space-between">
      
        <Flex column mr={"100px"} style={{ maxWidth: "750px" }}>
          <h1 className="slogan">
            Entre em contato conosco e resolva suas pendências.
          </h1>
          {/* <h1 className="slogan">
            Resolva suas pendências como motorista
          </h1> */}
          <p className="description">
            Se preferir, visite nosso escritório em Rua Carlos Von Koseritz, 25. 
            Bairro São João - Porto Alegre-RS. Estamos abertos de segunda à sexta, das 9h às 18h.
          </p>
        </Flex>
        <Flex>
          <ContactBox id="contact-box">
            <ContactForm/>
          </ContactBox>
        </Flex>
      
    </Flex>
  </BannerWrapper>
