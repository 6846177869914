import React, { Component, Fragment } from 'react'
import { Helmet } from "react-helmet"
import styled from 'styled-components'
import { Flex, Box } from 'reflexbox'

import pageBanner from '../../../assets/img/11.jpg'
import PageHeader from '../../template/PageHeader'

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 60px 0px;

  h1.page-title {
    font-size: 38px;
    margin-bottom: 25px;
    color: var(--red);
  }

  h2.page-subtitle {
    font-size: 24px;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  p.page-text {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`


class CnhCassada extends Component {
  render() {
    return (
      <Fragment>

        <Helmet>
          <title>CNH Cassada - Vargas Assessoria de Trânsito - Porto Alegre-RS</title>
        </Helmet>

        <PageHeader pageBanner={pageBanner} pageTitle={"CNH Cassada"}/>

        <div className="container">
          <ContentWrapper>
            <h1 className="page-title">
              CNH Cassada
            </h1>
            <h2 className="page-subtitle">
              Como ter a CNH cassada
            </h2>
            <p className="page-text">
              Existem 3 casos que podem ocasionar a CNH Cassada: 1º Dirigir com a CNH Suspensa; 
              2º Quando o infrator é reincidente, quando o motorista já teve a CNH suspensa, 
              recuperou o documento e nos primeiros 12 meses atingiu novamente 20 pontos; 3º Caso 
              seja condenado judicialmente por algum delito no trânsito.           
            </p>
            <h2 className="page-subtitle">
              O que fazer quando estiver com a CNH Cassada
            </h2>
            <p className="page-text">
              Com a cassação da CNH o motorista está proibido de dirigir por 2 anos e logo após 
              está pena o motorista terá que refazer todo o processo de habilitação novamente. 
              A pena de cassação só poderá ser aplicada depois da finalização do processo administrativo 
              instaurado pelo DETRAN, quando o motorista poderá se defender. O processo de cassação 
              cabe três recursos administrativos: a defesa prévia, a Jari e o Cetran.           
            </p>
            <p className="page-text">
              A <strong>Vargas Assessoria de Trânsito</strong> está desde 2000 ajudando vários motoristas com este 
              problema de CNH Suspensa e CNH Cassada, com uma gama de profissionais qualificados 
              prontos para ajudar você com esse problema. Existem alguns motivos em que a cassação 
              é revista: ao alegar nulidades no processo, erro nos prazos de julgamento e 
              necessidade do direito de dirigir.           
            </p>
          </ContentWrapper>
        </div>
        

      </Fragment>  
    )
  }
}
export default CnhCassada
  