import React from 'react'
import styled from 'styled-components'

import logo from '../../assets/img/logo_.png'

const LogoWrapper = styled.div`
  background: var(--yellow);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 15px 30px 25px 30px;
  margin-top: 0px;
  transition: opacity 1s ease-in-out;
`

const LogoImg = styled.img.attrs({
  src: logo,
  alt: 'Vargas Assessoria de Trânsito',
  height: '100%',
  width: '100%',
})``

const LogoMax = () => {
  return (
    <LogoWrapper id="logo">
      <LogoImg className="logo-img" />
    </LogoWrapper>
  )
}

export default LogoMax